<script>
import {Chart} from 'highcharts-vue'
import moment from 'moment'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { camelCase } from 'lodash';

export default {
   components: {
    Highcharts: Chart,
    VueSlider
  },
  props: {
    noCard: {
      type: Boolean,
      required: false
    },
    weights: {
      type: Array,
      required: true
    },
    children: {
      type: Array,
      required: true
    },

  },
  created(){
    this.camelCase = camelCase;
  },
  watch: {
    weights(oldValue, newValue) {
      if(oldValue != newValue) {
        this.rowIndex = null;
      }
    }
  },
  data() {
    return {
     rowIndex: null,
     formatter: val => {
        const row = this.compositionWeightsObj.rows[this.maxRows - val];
        const label = moment(row.date).format('L');
        return label;
     },
     marks: val => {
       const row = this.compositionWeightsObj.rows[this.maxRows - val];
       const label = moment(row.date).format('L');
       return {
          label: this.maxRows === val || 0 === val ? label : '',
        };
      }
    };
  },
  computed:{
    invertRowIndex(){
      return this.maxRows - this.currentRow;
    },
    currentRow: {
      get()  {
        return this.rowIndex !== null ? this.rowIndex : this.maxRows;
      },
      set (val) {
        this.rowIndex = val;
      }
    },
    maxRows(){
      return this.compositionWeightsObj && this.compositionWeightsObj.rows ? (this.compositionWeightsObj.rows.length-1) : 0;
    },
    row() {
      return this.compositionWeightsObj.rows[this.invertRowIndex];
    },
    compositionWeightsObj () {
      const compositionWeightsObj = {
        rows: this.weightsData.map(x => {
          let arr = [];
         
          this.children.forEach(a => {
            const av = x.values || {};
            const r =av[a.id] || av[camelCase(a.name)] || av[a.name] || [];
            const i = {...r};
            i.indexId = camelCase(a.name);
            i.indexName = a.name;
            arr.push(i);
          });
          
          let o = { 
            value: x.date, 
            date: new Date(x.date),
            sum: x.sum,
            sumStr: x.sumStr,
            origValues: arr,
            values: x.values
          };
          return o;
        })
      };
      compositionWeightsObj.rows.sort(function(a,b){return b.date.getTime() - a.date.getTime()});
      return compositionWeightsObj;
    },
    weightsChartOptions() {
      const compositionWeightsObj = this.compositionWeightsObj;

      if(compositionWeightsObj.rows.length > 0) {
        const latestYear = compositionWeightsObj.rows[this.invertRowIndex];
        console.log('latestYear', latestYear);
        // create weights pie chart
        let weightsPiaChartOptions = {
          credits:{
            enabled: false
          },
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
          },
          title: {
            text: ''
          },
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %' 
              }
            }
          },
          series: [{
            name: 'Brands',
            colorByPoint: true,
            data: latestYear.origValues.filter(x => x.value > 0.001).map(x => {
              return {
                name: `${x.indexName} (${x.valueStr})`,
                y: x.percValue
              };
            })
          }]
        };
        return weightsPiaChartOptions;  
      }

      return null;
    },
    mostRecentWeightsLast10YearsOptions() {
      const compositionWeightsObj = this.compositionWeightsObj;
      let mostRecentWeightsItems = [];
        let xLabels = [];
        const mostRecentWeightsLast10YearsRows = [];
        let a = [...compositionWeightsObj.rows];
        a = a.reverse();
        let startIndex = a.length-10;
        if(startIndex < 0)
          startIndex = 0;
        for(let i = startIndex; i <a.length; i++ ){
          mostRecentWeightsLast10YearsRows.push(a[i]);
        }
        
        mostRecentWeightsLast10YearsRows.forEach(row =>{
          const label = moment(row.date).format("DD/MM/YYYY");
          xLabels.push(label);
        });
      
        this.children.forEach(child => {
          let data = [];
          mostRecentWeightsLast10YearsRows.forEach(row =>{
            const indexValue = row.origValues.find(x => x.indexId === child.id);
            const v = (indexValue || {}).value || 0;
            data.push(v);
          });
          mostRecentWeightsItems.push( { name: child.name, data: data, color:  this.colors[child.id] } )
        });
       
       
        return {
           credits:{
            enabled: false
          },
           chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                categories: xLabels
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Weights'
                }
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f}</b> ({point.percentage:.1f}%)<br/>',
                shared: true
            },
            plotOptions: {
                column: {
                    stacking: 'percent'
                },
            },
            series: mostRecentWeightsItems
        };
    },
    weightsData(){
      return this.weights;
    },
    hasData() {
      return this.weights && this.weights.length > 0;
    }
  },
  methods: {
    getValuePerc(row, child) {
      var o = (row.values[child.id] || row.values[camelCase(child.name)] || row.values[child.name] || {}).percValue || 0;
      return parseFloat(o.toFixed(2));
    },
    getValue(row, child) {
      //console.log('row', {row, child, c: camelCase(child.name)});
      var o = (row.values[child.id] || row.values[camelCase(child.name)] || row.values[child.name] || {}).value || 0;
      return parseFloat(o.toFixed(2));
    },
    getRawValue(row, child) {
      //console.log('row', {row, child, c: camelCase(child.name)});
      var o = (row.values[child.id] || row.values[camelCase(child.name)] || row.values[child.name] || {}).value || 0;
      return o;
    }
  }
};
</script>

<template>
  <div v-if="hasData" :class="{'card cardc8': !noCard}">
    <div  :class="{'card-content': !noCard}">
      <div  :class="{'card-body': !noCard}">

        <!-- Header -->
        <div class="row" v-if="!noCard">
          <div class="col">
            <h5 class="card-title">
              Weights
              <span v-if="row" class="ml-2">
                {{row.date | moment('L')}}
              </span>
            </h5>
          </div>
        </div>

        <div class="row" v-if="noCard">
          <div class="col">
            <h5 class="card-title mb-4">
             <span v-if="row" class="ml-2">
                {{row.date | moment('L')}}
              </span>
            </h5>
          </div>
        </div>
       
        <div class="row">
          <div class="col-lg-6">
           
            <table class="table-borderless mb-0">
              <thead>
                <tr>
                  <th class="col-8">Instrument</th>
                  <th class="col-4">Weights</th>  
                </tr>
              </thead>
              <tbody>
                <template  v-for="child in children">
                  <tr :key="child.childId" v-if="getRawValue(row, child) > 0.001">
                    <td class="col-8">{{child.name}}
                    </td>
                    <td class="col-4">
                      <span style="width:50px;display: inline-block; text-align:right;">  
                        {{(getValuePerc(row, child))}}%
                      </span>
                      &nbsp;({{(getValue(row, child))}})
                    </td> 
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="col-lg-6">
            
            <highcharts 
              class="hc" 
              :options="weightsChartOptions" ref="weightsPiaChart">
            </highcharts>
          
          </div>
        </div>  
       
       
         <vue-slider 
          :tooltip="'always'"
          :tooltip-formatter="formatter"
          style="margin-top:5px;" 
          v-model="currentRow" 
          :min="0" 
          :max="maxRows" 
          :marks="marks"
          :interval="1">
        </vue-slider>   
      </div>
    </div>
  </div>
</template>